import { Log } from '@lightningjs/sdk'
import {
  DvrWindowDuration,
  PlaybackType,
  SessionItem,
  StreamingProtocol,
  VideoInitiate,
} from '@sky-uk-ott/core-video-sdk-js'

import AppConfigFactorySingleton from '../../config/AppConfigFactory'
import { DebugControllerSingleton } from '../../util/debug/DebugController'
import { getProxyDrmHmac } from '../../util/drm'
import appVersion from '../../version'
import { getAssetMetadata } from './getCoreVideoSdkMetadata'
import { getPreferredLanguages, getWhiteLabelBrand } from '../../helpers'
import MParticleApi from '../../api/MParticleApi'
import PlayerStoreSingleton, { Program, Stream } from '../../store/PlayerStore/PlayerStore'
import { isVod } from '../../components/player/helpers/metadata'
import { getCoreVideoSdkPlatform } from './getCoreVideoSdkPlatform'
import { getDRMDeviceName } from './getDRMDeviceName'
import TVPlatform from '../../lib/tv-platform'
import CoreVideoSdkLogLevels from './CoreVideoSdkLogLevels'
import UserInteractionsStoreSingleton from '../../store/UserInteractions'
import MvpdData from '../../api/models/MvpdData'
import { LemonadeResponse } from '../../lib/lemonade/Lemonade'
import { CONTENT_TYPE } from '../../constants'
import { isEpgStream } from '../../store/PlayerStore/actions/epg'

export type PlayerData = {
  program: Program
  stream: Stream
  lemonade: LemonadeResponse
}

const ProgrammingTypes: Record<string, PlaybackType> = {
  'Full Event Replay': PlaybackType.FER,
  'Single Live Event': PlaybackType.SingleLiveEvent,
  'Full Episode': PlaybackType.VOD,
  Clip: PlaybackType.Clip,
  'Web Exclusive': PlaybackType.Clip,
  Movie: PlaybackType.VOD,
  Highlight: PlaybackType.Clip,
  'Short-Form VOD': PlaybackType.Clip,
  Trailer: PlaybackType.Clip,
  Excerpt: PlaybackType.Clip,
  'Sneak Peek': PlaybackType.Clip,
  'Teaser Trailer': PlaybackType.Clip,
  Highlights: PlaybackType.Clip,
}

export const getSessionConfig = async ({
  mvpd,
  isNBCProfileLinked,
  mvpdHash,
  mParticleId,
  program,
  lemonade,
  product,
  stream,
  raw,
}: {
  mvpd: MvpdData | null
  isNBCProfileLinked?: boolean
  mvpdHash?: string
  mParticleId?: string
  program: Program
  lemonade: LemonadeResponse
  product: any
  stream: Stream
  raw: boolean
}): Promise<SessionItem> => {
  const drmConfiguration = getDrmConfiguration(program)
  const deviceAdvertisingTrackingConsent = !TVPlatform.getUserOptOut()
  const percentViewed = stream?.v4ID
    ? UserInteractionsStoreSingleton.getPercentViewed(stream?.v4ID)
    : 0
  const duration = (stream && 'duration' in stream && stream?.duration) || 0
  const startPosition = isVod(program?.programmingType)
    ? {
        startPosition: percentViewed < 0.95 ? percentViewed * duration : 0,
      }
    : {}
  Log.info('deviceAdvertisingTrackingConsent', deviceAdvertisingTrackingConsent)
  return {
    type: (() => {
      const programmingType = program?.programmingType || ''
      const type =
        // @ts-expect-error Property 'contentType' does not exist on type
        stream?.contentType === CONTENT_TYPE.LINEAR
          ? PlaybackType.Live
          : ProgrammingTypes[programmingType]
      const isLive = program?.adobeContentType === CONTENT_TYPE.LINEAR
      return !isLive && type ? type : PlaybackType.Live
    })(),
    ...(raw ? { manifests: [lemonade.playbackUrl] } : {}),
    protocol: (DebugControllerSingleton.videoProtocol ||
      lemonade?.type ||
      TVPlatform.streamingProtocol) as StreamingProtocol,
    autoplay: true,
    muted: false,
    ...startPosition,
    key: 'test_stream',
    vamMidrollEnabled: true,
    vamPrerollEnabled: true,
    vac: {
      deviceAdvertisingTrackingConsent,
      coppaApplies: false,
      mvpdHash,
      mParticleId,
    },
    advertising: {
      contentId:
        lemonade.adServerContentId ||
        (stream && 'externalAdvertiserId' in stream && stream?.externalAdvertiserId) ||
        '',
      adCompatibilityEncodingProfile: lemonade.adCompatibilityEncodingProfile || 'not_required',
    },
    ...drmConfiguration,
    reporting: {
      coppaApplies: false,
      assetMetadata: getAssetMetadata({
        program,
        lemonade,
        mvpd,
        product,
        isNBCProfileLinked,
        stream,
        sessionId: MParticleApi.getSessionId(),
      }),
      videoInitiate: VideoInitiate.Manual,
    },
    user: {
      // @ts-expect-error FIXME: check if we need to pass a boolean or a string
      authenticated: mvpd ? true : isNBCProfileLinked ? 'Free' : 'Unauthenticated',
      authenticationProvider: mvpd?.mvpd || 'NBC Profile',
      ids: {
        conviva: {
          profileid: mParticleId || '',
        },
      },
    },
    preferredAudioLanguages: getPreferredLanguages(),
    sessionRetryConfig: {
      enabled: true,
      cooldownPeriodSecs: 30,
    },
    ...(lemonade.pvrControls ? { preferredWindowDuration: DvrWindowDuration.Extended } : {}),
  }
}

export const getCoreVideoSdkConfig = () => {
  //TODO: device advertising id and device advertising id type should come from
  // lemonade and not the config
  const debug = DebugControllerSingleton.enabled
  const appIdentifier = TVPlatform.appIdentifier
  const { core_video_sdk, adobe, conviva, nielsen, comscore } =
    AppConfigFactorySingleton?.config || {}
  return {
    debug,
    proposition: 'OneApp',
    vpi: {
      useCustomVpip: true,
    },
    drm: {
      widevine: {},
    },
    devices: TVPlatform.devicesSdkConfig,
    loglevel: debug ? CoreVideoSdkLogLevels.verbose : CoreVideoSdkLogLevels.silent,
    addons: {
      adInsertion: {
        enabled: true,
        mediaTailorAdInsertionOverrideLinear: true,
        mediaTailorAdInsertionOverrideVod: true,
        mediaTailorAdInsertionOverrideFer: true,
        mediaTailorAdInsertionOverrideSle: true,
        vac: {
          type: 'VAM',
          url:
            (core_video_sdk?.vac?.host ?? 'https://video-ads-module.ad-tech.nbcuni.com') +
            (core_video_sdk?.vac?.endpoint ?? '/v1/freewheel-params'),
          requestData: {
            appIdentifier,
            appVersion,
            appName: 'oneapp',
            appBrand: getWhiteLabelBrand().name,
            appBundleId: getWhiteLabelBrand().bundleId,
            appBuild: appVersion,
            deviceAdvertisingId: TVPlatform.getAdvertiserId(),
            deviceAdvertisingIdType: TVPlatform.getDeviceAdvertisingIdType(),
            platform: getCoreVideoSdkPlatform(),
          },
        },
        mediaTailor: {
          ...(core_video_sdk?.mediaTailor ?? {}),
        },
      },
      common: {
        appIdentifier,
        appName: getWhiteLabelBrand().nielsen,
        appVersion,
        appBrand: getWhiteLabelBrand().app,
        devId: TVPlatform.deviceId,
      },
      reporting: {
        userWatchedAt: new Date(),
        videoInitiate: 'Manual',
        adobe: {
          enabled: adobe?.enabled ?? true,
          config: {
            isDebug: adobe?.debug ?? false,
            adobeId: adobe?.publisherId ?? '',
            account: adobe?.rsid ?? '',
            mediaHeartbeatTrackingServer: adobe?.hbTrackingServer ?? '',
            analyticsTrackingServer: adobe?.appTrackingServer ?? '',
            onlineVideoPlatform: adobe?.ovp ?? '',
            // @ts-expect-error TS(2339): Property 'visitor' does not exist on type 'Window ... Remove this comment to see the full error message
            visitorId: window?.visitor?.getAnalyticsVisitorID?.() ?? '',
          },
        },
        comscore: {
          enabled: comscore?.enabled ?? true,
          config: {
            customerC2: comscore?.customerC2 ?? '6035083',
            publisherSecret: comscore?.publisherSecret ?? 6035083,
            allowOfflineTransmission: comscore?.allowOfflineTransmission ?? true,
          },
        },
        conviva: {
          enabled: conviva?.enabled ?? true,
          config: {
            customerKey: conviva?.api_key ?? '5236d0980748b4d4e0ea4dec01db0a78006ad447',
            gatewayUrl: conviva?.gatewayUrl ?? 'https://nbcu-central-test.testonly.conviva.com',
            mutedErrorCodes: [], // specific error codes that we have blocked for reporting
            enableAdInsights: conviva?.enableAdInsights ?? true,
            shouldUseLegacyLibrary: false,
            cdn: 'AKAMAI',
          },
        },
        nielsen: {
          enabled: nielsen?.enabled ?? true,
          config: {
            cloudAppId: nielsen?.id,
            airdate: new Date(),
            enableProduction: nielsen?.enableProduction ?? true,
          },
        },
      },
    },
    players: {
      aamp: {
        enableAsyncTune: true,
      },
      shaka: {},
    },
  }
}

const getDrmConfiguration = (program: Program) => {
  return {
    drmConfiguration: {
      type: TVPlatform.drmType,
      licenceAcquisitionUrl: buildLicenseAcquisitionUrl(program?.callSign),
    },
  }
}

const buildLicenseAcquisitionUrl = (callSign?: string | null) => {
  const { core_video_sdk, colorShader } = AppConfigFactorySingleton?.config || {}
  callSign = callSign ? `&keyId=${encodeURI(callSign)}` : ''
  const { hash, time } = getProxyDrmHmac(colorShader, TVPlatform.drmType)

  return `${core_video_sdk?.drm
    ?.licenceUrl}/${TVPlatform.drmType.toLowerCase()}?time=${time}&hash=${hash}&device=${getDRMDeviceName()}${callSign}`
}

export const getCoreStreamUrl = (lemonade: LemonadeResponse) =>
  DebugControllerSingleton.streamUrl || lemonade?.playbackUrl || lemonade?.playbackUrls

export const getPlayoutData = (vod: boolean) => {
  const { stream, program, lemonade } = PlayerStoreSingleton
  if (!stream || !program || !lemonade) return
  const epgStream = isEpgStream(stream)
  const externalAdvertiserId = 'externalAdvertiserId' in stream ? stream?.externalAdvertiserId : ''
  const mpxGuid = 'mpxGuid' in stream ? stream?.mpxGuid : ''
  const durationInMilliseconds =
    'durationInMilliseconds' in program ? program?.durationInMilliseconds : ''
  const callSign = 'callSign' in program ? program?.callSign : ''
  const adCompatibilityEncodingProfile =
    'adCompatibilityEncodingProfile' in program ? program?.adCompatibilityEncodingProfile : ''
  const adServerContentId = 'adServerContentId' in program ? program?.adServerContentId : ''
  const isVOD = 'programmingType' in program && isVod(program?.programmingType)

  const endpointData = lemonade?.playbackUrls || [
    {
      url: getCoreStreamUrl(lemonade),
      cdn: 'AKAMAI',
    },
  ]
  const livePlayoutData = {
    statusCode: 200,
    type: 'content',
    ...(lemonade
      ? {
          advertising: {
            assetId: lemonade.adServerContentId || !epgStream ? externalAdvertiserId : '',
            contentId: lemonade.adServerContentId || externalAdvertiserId,
          },
        }
      : {}),
    ovpData: {
      nielsenTrackingType:
        isVOD ||
        !lemonade ||
        lemonade.adInstructions?.dai_MidRollEnabled ||
        lemonade.adInstructions?.dai_PreRollEnabled
          ? 'DCR'
          : 'DTVR',
      asset: {
        endpoints: endpointData,
        format: {
          protection: TVPlatform.drmType,
          transport:
            vod && lemonade?.type
              ? (lemonade?.type as StreamingProtocol)
              : TVPlatform.streamingProtocol,
          acodec: 'AAC',
          vcodec: 'H264',
          container: 'ISOBMFF',
        },
      },
      protection: {
        licenceAcquisitionUrl: buildLicenseAcquisitionUrl(callSign),
        type: TVPlatform.drmType.toUpperCase(),
      },
      thirdParties: {
        FREEWHEEL: {
          adCompatibilityEncodingProfile: adCompatibilityEncodingProfile || 'not_required',
          contentId: lemonade.adServerContentId || adServerContentId || externalAdvertiserId,
        },
      },
      videoFormat: 'UNKNOWN',
      durationMs: durationInMilliseconds || 0,
      ...(lemonade?.adInstructions
        ? {
            adInstructions: {
              //These need to be strings for core video
              dai_MidRollEnabled: lemonade.adInstructions.dai_MidRollEnabled ? 'true' : 'false',
              dai_PreRollEnabled: lemonade.adInstructions.dai_PreRollEnabled ? 'true' : 'false',
            },
          }
        : {}),
      contentId: mpxGuid,
    },
  }

  Log.info('getCoreVideoSdkConfig.js livePlayoutData', livePlayoutData)
  return livePlayoutData
}
