import { Language, Router, Utils, Storage } from '@lightningjs/sdk'

import SettingsButton from '../../../components/buttons/SettingsButton'
import BaseComponent from '../../../components/base'

import { COLORS, FLEX_DIRECTION, FONT_FACE, ROUTE } from '../../../constants'
import { getAboutMenuItemsByBrand } from './aboutItemsHelper'
import { RouterPage } from '../../../../types/global'

export default class About extends BaseComponent<RouterPage> {
  _index: any
  _items: any
  announce: any

  static override _template() {
    return {
      x: 80,
      y: 200,
      w: 1760,
      h: 810,
      rect: true,
      color: COLORS.dark5,

      PageTitleHolder: {
        x: 100,
        y: 78,
        Icon: {
          h: 40,
          w: 40,
          src: Utils.asset('images/settings/about.png'),
        },
        Title: {
          x: 60,
          text: {
            text: Language.translate('about'),
            fontSize: 30,
            fontFace: FONT_FACE.light,
          },
        },
      },

      Content: {
        y: 178,
        x: 233,

        CaptionOptionButtons: {
          flex: { direction: FLEX_DIRECTION.column },
        },
      },
    }
  }

  override _init() {
    this.stage.setClearColor(COLORS.dark)
    this.createButtons()
    this._index = Storage.get('AboutIndex') || 0
  }

  override _active() {
    this.widgets.menu.collapse({
      subCategoryItem: Language.translate('about'),
      hideProfile: true,
    })
    this._setState('LanguageButton')
  }

  override _inactive() {
    this.widgets.menu.expand()
  }

  override _setTranslation() {
    const aboutText = Language.translate('about')
    this.tag('PageTitleHolder.Title').patch({ text: { text: aboutText } })
    this.announce = aboutText
  }

  createButtons() {
    this._items = getAboutMenuItemsByBrand()

    this.tag('CaptionOptionButtons').patch({
      children: this._items?.length
        ? this._items.map((item: any) => {
            return {
              flexItem: { marginTop: item.marginTop ? item.marginTop : 10 },
              w: 740,
              h: 70,
              type: SettingsButton,
              radius: 0,
              fontSize: 30,
              fontFace: FONT_FACE.light,
              focusFontColor: COLORS.dark,
              unfocusFontColor: COLORS.white,
              focusBackGroundColor: COLORS.lightGray3,
              unfocusBackgroundColor: COLORS.black3,
              label: Language.translate(item.label),
              padding: 0,
              icon: item.icon,
            }
          })
        : [],
    })
  }

  get focusItem() {
    return this.getChildItem(this._index)
  }

  getChildItem(index = 0) {
    return this.tag('CaptionOptionButtons').children[index]
  }

  override _getFocused() {
    return this.focusItem || this
  }

  override _handleUp() {
    if (this._index > 0) {
      this._index--
    }
  }

  override _handleDown() {
    if (this._index < this.tag('CaptionOptionButtons').children.length - 1) {
      this._index++
    }
  }

  override _handleBack(e: Event) {
    e.preventDefault()
    // destroy the stored index when we leave the About Settings page
    Storage.remove('AboutIndex')
    Router.navigate(ROUTE.settings, { reload: true }, false)
  }

  override _handleEnter() {
    const item = this._items[this._index]
    // Save index for returning.
    Storage.set('AboutIndex', this._index)
    const route = item.label === 'debug' ? 'debug' : 'longtext'
    Router.navigate(route, {
      url: item.text[Language.get()],
      subcat: item.label,
      bustCache: item.bustCache,
    })
  }
}
