import { Language, Router, Storage, Utils } from '@lightningjs/sdk'
import { Subscription } from 'rxjs'
import { updateLanguage } from './moment'

import routerConfig from './routerConfig'

import Loader from './components/loader/Loader'
import { ConfirmationDialog, ExitDialog, Menu, Notification } from './widgets'

import Styler from './lib/Styler'
import style from './lib/style'
import AppConfigFactorySingleton from './config/AppConfigFactory'
import AnalyticsSingleton from './lib/analytics/Analytics'
import TVPlatform from './lib/tv-platform'

import { AudioLanguageTag, getBrandName } from './helpers'
import { BRAND, COLORS, LANGUAGES, ROUTE, SCREEN_SIZE, STORAGE_KEYS } from './constants'
import { NavigationEvent, PlatformSubscriptionType } from './lib/tv-platform/base'
import RouterUtil from './util/RouterUtil'
import { ModalManagerEvent, ModalManagerEventTypes } from './lib/ModalManager'
import { SubscriptionBuilder, SubscriptionSources } from './util/SubscriptionBuilder'
import { ModalHandler, loadModals, createModal, updateModal } from './widgets/Modals'
import Announcer from './lib/tts/Announcer'
import { AppStateManager } from './lib/AppStateManager'
import { ACTIVATION_TYPE } from './widgets/Modals/activation/constants'
import LaunchDarklySingleton from './lib/launchDarkly/LaunchDarkly'
import { notifyUser } from './widgets/Modals/activation/notification'

export default class App extends Router.App {
  _subscription?: Subscription
  _modal?: ModalHandler

  static getFonts() {
    return []
  }

  override _focusChange() {
    Announcer.onFocusChange(this.application.focusPath)
  }

  override _setup() {
    Styler.compile(style)
    routerConfig.events = {
      navigation: () => {
        this.tag('Menu').patch({ currentButton: Router.getActiveHash() })
      },
    }
    Router.startRouter(routerConfig)
    AppConfigFactorySingleton.get().events.subscribe((config: any) =>
      this._onAppConfigLoaded(config)
    )
    if (Language.get() !== Storage.get(STORAGE_KEYS.APP_LANGUAGE)) {
      Language.set(Storage.get(STORAGE_KEYS.APP_LANGUAGE))
    }
    if (!Storage.get(STORAGE_KEYS.PREFERRED_AUDIO_LANG)) {
      Storage.set(STORAGE_KEYS.PREFERRED_AUDIO_LANG, AudioLanguageTag.en)
    }
    updateLanguage(Language.get())
  }

  _onAppConfigLoaded(config: any) {
    const lang = config.preferredLanguage
    if (lang) {
      Storage.set(STORAGE_KEYS.APP_LANGUAGE, lang)
      Language.set(lang)
      updateLanguage(lang)
    }
  }

  override _handleBack(e: any) {
    Router.back()
    e.preventDefault()
    e.stopPropagation()
  }

  override _firstActive() {
    this._subscription = new SubscriptionBuilder()
      .with(
        {
          type: SubscriptionSources.TV_PLATFORM,
          event: PlatformSubscriptionType.VOICE_CONTROL,
          handler: this._onVoiceControlEvent,
        },
        {
          type: SubscriptionSources.TV_PLATFORM,
          event: PlatformSubscriptionType.VOICE,
          handler: this._onTTSEvent,
        },
        {
          type: SubscriptionSources.MODAL,
          handler: this._onModalEvent,
        },
        {
          type: SubscriptionSources.AUTHENTICATION,
          handler: this._onAuthEvent,
        }
      )
      .subscribe()
    AppStateManager.init()
  }

  override _inactive() {
    this._subscription?.unsubscribe()
  }

  /**
   * Exit key handler
   * @private
   */
  override _handleExit() {
    this.$close()
  }

  override _handleAppClose() {
    if (Router.getActiveRoute() === ROUTE.home) {
      this.$close()
    } else {
      Router.navigate(ROUTE.home)
    }
  }

  $close() {
    AnalyticsSingleton.onAppClosed()
    TVPlatform.exit()
    // @ts-expect-error TS2339: Property 'closeApp' does not exist on type 'Application<TemplateSpecLoose, TypeConfig>'.
    this.application?.closeApp()
  }

  $exitToPeacock() {
    AnalyticsSingleton.onAppClosed()
    TVPlatform.exitToPeacock()
  }

  static language() {
    return {
      file: Utils.asset('translations/translations.json'),
      language:
        Storage.get(STORAGE_KEYS.APP_LANGUAGE) ||
        (getBrandName() !== BRAND.telemundo ? LANGUAGES.DEFAULT : LANGUAGES.SPANISH),
    }
  }

  static override _template() {
    return {
      ...super._template(),
      Widgets: {
        Menu: {
          type: Menu,
          activeIndex: 0,
          items: [],
        },
        Loader: {
          visible: false,
          type: Loader,
          zIndex: 100,
          y: 540,
          mountY: 0.5,
        },
        // #region Modals start
        // Reserve 200 range zIndex for modals
        ...loadModals(),
        // #endregion
        Notification: {
          type: Notification,
          zIndex: 300,
          visible: false,
        },
        ExitDialog: {
          type: ExitDialog,
          zIndex: 301,
          visible: false,
        },
        ConfirmationDialog: {
          type: ConfirmationDialog,
          zIndex: 302,
          visible: false,
        },
      },
      Loading: {
        rect: true,
        w: SCREEN_SIZE.width,
        h: SCREEN_SIZE.height,
        color: COLORS.dark,
        visible: false,
        zIndex: 99,
        Loader: {
          y: 540,
          mountY: 0.5,
          type: Loader,
        },
      },
    }
  }

  _onVoiceControlEvent = (e: NavigationEvent) => {
    if (!e.entity) return Router.navigate(e.route)
    RouterUtil.navigateToRoute(e.route, { entity: e.entity.entityType, value: e.entity.value })
  }

  _onTTSEvent = (value: boolean) => {
    Announcer.enabled = value
  }

  _onModalEvent = (evt: ModalManagerEvent) => {
    return {
      [ModalManagerEventTypes.CLOSE]: () => {
        this._modal?.destroy(evt.focusPage)
      },
      [ModalManagerEventTypes.OPEN]: () => {
        this._modal = createModal(this, evt.type, evt.payload)
      },
      [ModalManagerEventTypes.UPDATE]: () => {
        if (this._modal) updateModal(this, evt.type, evt.payload)
      },
    }[evt.event]()
  }

  _handleForceButtonPress = (keyCode: number) => {
    const keyboardEvent = new KeyboardEvent('keydown', {
      bubbles: true,
      cancelable: true,
      shiftKey: false,
      altKey: false,
      keyCode: keyCode,
    })
    document.dispatchEvent(keyboardEvent)
  }

  _onAuthEvent = (type: ACTIVATION_TYPE) => {
    LaunchDarklySingleton.updateUserAuthContext(true)
    if (type !== ACTIVATION_TYPE.TEMPPASS) notifyUser(type)
  }

  $getWidget(name: keyof Router.CustomWidgets) {
    return this.tag(name)
  }
}
