import { Language, Lightning, Registry, Storage, Router } from '@lightningjs/sdk'

import SettingsButton from '../../components/buttons/SettingsButton'
import RadioButton from '../../components/buttons/RadioButton'

import { COLORS, FLEX_DIRECTION, FONT_FACE, ROUTE, STORAGE_KEYS } from '../../constants'
import RouterUtil from '../../util/RouterUtil'
import TVPlatform from '../../lib/tv-platform'
import { RouterPage } from '../../../types/global'
import Announcer from '../../lib/tts/Announcer'

const WRAPPER_HEIGHT = 810
const CONTENT_OFFSET = 80
const SCROLL_STEP = 200

export default class LongText extends Lightning.Component<
  Lightning.Component.TemplateSpecLoose,
  RouterPage
> {
  _params: any
  _text: any
  announce: any
  _leftOverText: any
  _leftOverTimeout: any

  static translatedHeadings = [
    {
      en: 'Last Updated',
      es: Language.translate('settingsPage-longText-lastUpdated'),
    },
    {
      en: 'Do Not Sell My Personal Information',
      es: Language.translate('settingsPage-longText-doNotSellTitle'),
    },
    {
      en: 'Privacy Center',
      es: Language.translate('settingsPage-longText-privacyCenter'),
    },
  ]

  static override _template() {
    return {
      x: 80,
      y: 200,
      w: 1760,
      h: WRAPPER_HEIGHT,
      rect: true,
      color: COLORS.dark5,
      clipping: true,

      TextWrapper: {
        x: 120,
        y: CONTENT_OFFSET,
        w: 1400,
        Terms: {
          w: (w: any) => w,
          flex: { direction: FLEX_DIRECTION.column },
        },
      },
    }
  }

  override _detach(): void {
    super._detach()
    if (this._leftOverTimeout) Registry.clearTimeout(this._leftOverTimeout)
  }

  override _onDataProvided() {
    RouterUtil.setAppState('')
  }

  override _firstActive() {
    RouterUtil.setAppState('Loading')
  }

  override _init() {
    this.stage.setClearColor(COLORS.dark)
  }

  override _focus() {
    this.widgets.menu.collapse({
      subCategoryItem: this._params?.subcat,
      hideProfile: true,
    })
  }
  override _inactive() {
    this.widgets.menu.expand()
  }
  _isDoNotSell() {
    return this._params.subcat === Language.translate('do_not_sell_my_personal_information')
  }
  _getFocusStateByType() {
    return this._isDoNotSell() ? 'Toggle' : 'BackButton'
  }
  set apiData(data: any) {
    this.text = data
  }

  _generateTermsText(text: any) {
    return text.map((txt: any) => {
      return {
        w: 1400,
        color: COLORS.white,
        text: {
          text: txt.replace(/(<([^>]+)>)/gi, '\r \n'),
          fontSize: 30,
          lineHeight: 35,
          textBaseline: 'Top',
          fontFace: FONT_FACE.light,
        },
      }
    })
  }

  _setBottomTerms() {
    if (this._isDoNotSell()) {
      this.tag('Terms').patch({
        Toggle: {
          flexItem: { marginTop: 25 },
          w: 740,
          h: 200,
          signals: { onSave: '_handleUserOptOutChange' },
          type: RadioButton,
          value: !TVPlatform.getUserOptOut(),
          radius: 0,
          padding: 0,
        },
      })
      this.tag('Toggle').Title = 'Manage Preferences'
      this.tag('Toggle').Label = 'Allow Sale of My Personal Information'
    } else {
      this.tag('Terms').patch({
        BackButton: {
          flexItem: { marginTop: 40, marginBottom: 60 },
          w: 740,
          h: 70,
          type: SettingsButton,
          radius: 0,
          fontSize: 30,
          fontFace: FONT_FACE.light,
          focusFontColor: COLORS.dark,
          unfocusFontColor: COLORS.white,
          focusBackGroundColor: COLORS.lightGray3,
          unfocusBackgroundColor: COLORS.black3,
          label: Language.translate('back'),
          padding: 0,
        },
      })
    }
  }

  _addLeftOverText() {
    const addOnText = this._generateTermsText(this._leftOverText)
    this.tag('Terms').children = [...this.tag('Terms').children, ...addOnText]
    if (this._leftOverTimeout) Registry.clearTimeout(this._leftOverTimeout)
    this._setBottomTerms()
  }

  override set text(text: any) {
    this.tag('Terms').children = []
    this._text = []
    this.stage.gc()

    const regex = /[\r\n]/gm
    const content = this._translateContentHeading(text)
    this._text = content.split(regex).filter((txt: any) => txt.length > 0)

    let attachText = this._text
    // check to see if we have more than 200 elements, if so we need to delay render
    if (this._text.length > 200) {
      const halfPage = Math.floor(this._text.length / 2)
      const _textCopy = [...this._text]
      attachText = _textCopy.splice(0, halfPage)
      this._leftOverText = _textCopy
      this._leftOverTimeout = Registry.setTimeout(() => {
        this._addLeftOverText()
      }, 1000)
    }
    this.tag('Terms').children = this._generateTermsText(attachText)
    if (!this._leftOverText) this._setBottomTerms()
    Registry.setTimeout(() => {
      Announcer.announce(this._text)
      if (this._isMaxScroll()) this._setState(this._getFocusStateByType())
    })
  }

  _translateContentHeading(content: any) {
    if (Language.get() === 'es') {
      let translatedContent = content
      LongText.translatedHeadings.forEach((heading: any) => {
        if (content.indexOf(heading.en) > -1)
          translatedContent = translatedContent.replace(heading.en, Language.translate(heading.es))
      })
      return translatedContent
    }
    return content
  }

  override _onUrlParams(args: any) {
    this._params = args
  }

  _scrollTo(y: any) {
    if (y !== this.tag('Terms').finalY)
      this.tag('Terms').patch({
        y,
      })
  }

  override _handleDown() {
    if (this.tag('Terms').finalH < WRAPPER_HEIGHT + CONTENT_OFFSET) return
    const maxScrollY = this.tag('Terms').finalH + CONTENT_OFFSET - WRAPPER_HEIGHT
    const isMaxScroll = this._isMaxScroll()
    if (isMaxScroll) this._setState(this._getFocusStateByType())
    else this._setState('')

    this._scrollTo(Math.floor(isMaxScroll ? -maxScrollY : this.tag('Terms').y - SCROLL_STEP))
  }

  override _handleUp() {
    if (Math.floor(this.tag('Terms').finalY) === 0) return
    this._setState('')
    this._scrollTo(this.tag('Terms').y + SCROLL_STEP >= 0 ? 0 : this.tag('Terms').y + SCROLL_STEP)
  }

  _handleUserOptOutChange(value: any) {
    // Since 0 is true we have to invert the value
    Storage.set(STORAGE_KEYS.USER_OPT_OUT, value ? 0 : 1)
    Registry.setTimeout(() => {
      Router.navigate(ROUTE.privacy)
    }, 1500)
  }

  _isMaxScroll() {
    return (
      this.tag('Terms').y - SCROLL_STEP <=
      -(this.tag('Terms').finalH + CONTENT_OFFSET - WRAPPER_HEIGHT)
    )
  }

  static override _states() {
    return [
      class BackButton extends this {
        override _getFocused() {
          return this.tag('BackButton') || this
        }
        override _handleEnter() {
          Router.step(-1)
        }
      },
      class Toggle extends this {
        override _getFocused() {
          return this.tag('Toggle') || this
        }
        override _handleEnter() {
          if (this.tag('Toggle')) this.tag('Toggle').toggle()
        }
      },
    ]
  }
}
