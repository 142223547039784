import { Utils } from '@lightningjs/sdk'

import TextButton from './TextButton'

import { COLORS, FONT_FACE } from '../../constants'

// @ts-expect-error TS(2417): Class static side 'typeof SettingsButton' incorrec... Remove this comment to see the full error message
export default class SettingsButton extends TextButton {
  override _focusFontColor: any
  _icon: any
  override _unfocusFontColor: any
  _value: any
  static override _template() {
    return {
      ...super._template(),
      Label: {
        x: 20,
        y: (h: any) => h / 2 + 3,
        mountY: 0.5,
      },
    }
  }

  set icon(icon: any) {
    this._icon = true
    this.patch({
      Icon: {
        h: this.h,
        w: this.h,
        mountX: 1,
        x: this.w,
        Image: {
          mount: 0.5,
          x: (w: any) => w / 2,
          y: (h: any) => h / 2,
          src: Utils.asset(icon),
        },
      },
    })
  }

  set value(v: any) {
    this._value = true
    this.patch({
      Value: {
        mountX: 1,
        mountY: 0.5,
        x: this.w - this.h,
        y: this.h / 2 + 3,
        color: COLORS.mediumGray3,
        text: {
          text: v,
          fontFace: FONT_FACE.light,
          fontSize: 30,
        },
      },
    })
  }

  override _focus() {
    super._focus()

    if (this._icon) {
      this.tag('Icon').patch({
        Image: {
          color: this._focusFontColor,
        },
      })
    }

    if (this._value) {
      this.tag('Value').patch({
        color: COLORS.black4,
      })
    }
  }

  override _unfocus() {
    super._unfocus()

    if (this._icon) {
      this.tag('Icon').patch({
        Image: {
          color: this._unfocusFontColor,
        },
      })
    }

    if (this._value) {
      this.tag('Value').patch({
        color: COLORS.mediumGray3,
      })
    }
  }

  override _handleEnter(): boolean | void {
    return false
  }
}
