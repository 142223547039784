import { Language } from '@lightningjs/sdk'

import AppConfigFactorySingleton from '../../../config/AppConfigFactory'
import { getUserProfile } from '../../../api/Identity'
import { DebugControllerSingleton } from '../../../util/debug/DebugController'
import { getBrandName } from '../../../helpers'

const defaultItemIcon = 'images/settings/circle_arrow.png'

const getAboutItemsData = () => {
  const { config = {} } = AppConfigFactorySingleton
  const { nielsenDisclosure, terms_conditions: termsConditions = {} } = config
  const { en: englishTerms, es: spanishTerms } = termsConditions

  return {
    terms: {
      label: Language.translate('terms_conditions'),
      icon: defaultItemIcon,
      text: {
        en: englishTerms,
        es: spanishTerms,
      },
    },
    nielsen: {
      label: Language.translate('nielsen_digital_measurement'),
      icon: defaultItemIcon,
      text: {
        en: nielsenDisclosure,
        es: nielsenDisclosure,
      },
    },
    videoViewing: {
      label: Language.translate('video_viewing_policy'),
      icon: defaultItemIcon,
      text: {
        en: 'https://nbcapp.nbc.co/json/app_nbc_videoservices.json',
        es: 'https://nbcapp.nbc.co/json/app_nbc_videoservices_espanol.json',
      },
    },
    debug: {
      label: Language.translate('debug_menu'),
      icon: defaultItemIcon,
      text: {
        en: '',
        es: '',
      },
    },
  }
}

export const getAboutMenuItemsByBrand = () => {
  let aboutItems
  const items = getAboutItemsData()
  switch (getBrandName()) {
    case 'nbcsports':
      aboutItems = [
        ...(DebugControllerSingleton.enabled ? [items.debug] : []),
        items.terms,
        items.nielsen,
      ]
      break
    default:
      aboutItems = [
        ...(DebugControllerSingleton.enabled ? [items.debug] : []),
        items.terms,
        items.nielsen,
        ...(!!getUserProfile() ? [items.videoViewing] : []),
      ]
  }
  return aboutItems.filter((item) => !!item)
}
