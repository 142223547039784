import { Language } from '@lightningjs/sdk'

import { getCMPIntakeParam, getBrandName } from '../../../helpers'

const defaultItemIcon = 'images/settings/circle_arrow.png'

const getPrivacyItemsData = () => {
  const cmpIntakeParam = getCMPIntakeParam()

  return {
    privacy: {
      label: Language.translate('privacy_policy'),
      icon: defaultItemIcon,
      bustCache: true,
      text: {
        en: `https://www.nbcuniversal.com/privacy/english/privacy?format=txt${cmpIntakeParam}`,
        es: `https://www.nbcuniversal.com/privacy/espa%C3%B1ol%20(spain)/privacy/spanish?format=txt${cmpIntakeParam}`,
      },
    },
    doNotSell: {
      label: Language.translate('do_not_sell_my_personal_information'),
      icon: defaultItemIcon,
      text: {
        en: `https://www.nbcuniversal.com/privacy/notrtoo.txt?${cmpIntakeParam}`,
        es: `https://www.nbcuniversal.com/privacy/notrtoo-spanish.txt?${cmpIntakeParam}`,
      },
    },
    ca: {
      label: Language.translate('ca_notice'),
      icon: defaultItemIcon,
      text: {
        en: `https://www.nbcuniversal.com/privacy/ca-notice.txt?${cmpIntakeParam}`,
        es: `https://www.nbcuniversal.com/privacy/ca-notice-spanish.txt?${cmpIntakeParam}`,
      },
    },
  }
}

export const getPrivacyMenuItemsByBrand = () => {
  let privacyItems
  const items = getPrivacyItemsData()
  switch (getBrandName()) {
    default:
      privacyItems = [items.privacy, items.doNotSell, items.ca]
  }
  return privacyItems.filter((item) => !!item)
}
