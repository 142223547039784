import { Lightning, Utils } from '@lightningjs/sdk'

import { formatTime } from '../../../helpers'
import { COLORS, FONT_FACE, TEXT_ALIGN } from '../../../constants'
import { useProgress } from './hooks/useProgress'
import SeekablePlayerControlsV1 from './SeekablePlayerControlsV1'

export default class SeekablePlayerControlsV2 extends SeekablePlayerControlsV1 {
  set seekIconV2(seek: any) {
    this.tag('SeekBar')?.patch({
      BigCircle: undefined,
      SmallCircle: undefined,
      Metadata: undefined,
      Shader: undefined,
    })
    switch (seek) {
      case 0:
        this.tag('SeekBar')?.patch({
          Shader: {
            rect: true,
            w: 24,
            h: 24,
            x: 0,
            y: 0,
            mountX: 0.5,
            shader: {
              type: Lightning.shaders.RoundedRectangle,
              strokeColor: COLORS.white,
              fillColor: COLORS.red,
              stroke: 3,
              radius: 12,
            },
          },
        })
        break
      case -1:
      case -2:
      case -3:
      case 1:
      case 2:
      case 3: {
        const isRew = seek < 0
        const direction = isRew ? -1 : 1
        const start = isRew ? 0 : seek
        const end = 3 - Math.abs(seek)
        const fill = Array(end).fill(0)
        const arrows = [1, 1, 1]
        arrows.splice(start, end, ...fill)
        this.tag('SeekBar')?.patch({
          Shader: {
            rect: true,
            w: 94,
            h: 32,
            x: 0,
            y: -8,
            mountX: 0.5,
            shader: {
              type: Lightning.shaders.RoundedRectangle,
              fillColor: COLORS.red,
              stroke: 0,
              radius: 16,
            },
          },
          Metadata: {
            Arrows: {
              x: isRew ? -30 : -23,
              y: 0,
              ...arrows.reduce(
                (acc: Record<string, any>, type: number, index: number) => ({
                  ...acc,
                  [`Arrow${index}`]: {
                    src: Utils.asset('images/player/arrow.png'),
                    w: 17,
                    h: 15,
                    x: 17 * index,
                    color: type === 1 ? COLORS.white : COLORS.mediumGray4,
                    scaleX: direction,
                  },
                }),
                {}
              ),
            },
            Time: {
              y: -50,
              color: COLORS.white,
              mountX: 0.5,
              text: {
                fontSize: 28,
                fontFace: FONT_FACE.regular,
                textAlign: TEXT_ALIGN.center,
              },
            },
          },
        })
        break
      }
      case 10:
      case -10:
        this.tag('SeekBar')?.patch({
          Metadata: {
            Arrow: {
              y: -25,
              mount: 0.5,
              src: Utils.asset('images/player/jump.png'),
              w: 30,
              h: 34,
              scaleX: seek < 0 ? 1 : -1,
            },
            Time: {
              y: -31,
              color: COLORS.white,
              mountX: 0.5,
              text: {
                fontSize: 12,
                fontFace: FONT_FACE.bold,
                textAlign: TEXT_ALIGN.center,
                text: 10,
              },
            },
          },
          Shader: {
            rect: true,
            w: 24,
            h: 24,
            x: 0,
            y: 0,
            mountX: 0.5,
            shader: {
              type: Lightning.shaders.RoundedRectangle,
              strokeColor: COLORS.white,
              fillColor: COLORS.red,
              stroke: 3,
              radius: 12,
            },
          },
        })
        break
      default:
        break
    }
  }

  updateSeekBarTimerV2() {
    // TODO: move this into separate components once we use V2 controls for VOD and FER
    const [time, _, range] = useProgress().value
    if (range) {
      const text = formatTime(Date.now() / 1000 - (range[1] - time))
      this.tag('Metadata.Time')?.patch({
        text: {
          text,
        },
      })
    }
  }
}
